body.landing-page {
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #287cb5;
}

div.welcome-content {
  color: white;
  width: 75%;
  height: 100%;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding-bottom: 115px;
  padding-top: 10px;
}

@mixin buttonColor($fontColor, $bgColor) {
  $shadowColor: darken($bgColor, 10%);
  -moz-box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  -webkit-box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  background-color: darken($bgColor, 10%);
  border: 1px solid lighten($fontColor, 10%);
  color: $fontColor;
  //text-shadow:0px 1px 0px darken($fontColor, 10%);
  &:hover {
    background-color: darken($bgColor, 20%);
  }
  &:visited,
  &:link {
    color: $fontColor;
  }
  &:active {
    position: relative;
    top: 1px;
    left: 1px;
    -moz-box-shadow: inset 0px 1px 0px 0px $shadowColor;
    -webkit-box-shadow: inset 0px 1px 0px 0px $shadowColor;
    box-shadow: inset 0px 1px 0px 0px $shadowColor;
  }
  &:disabled {
    -moz-box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    -webkit-box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    border-color: desaturate($fontColor, 60%);
    background-color: desaturate($bgColor, 60%);
    color: desaturate($fontColor, 60%);
  }
}

.big-button {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: inline-block;
  font-size: 1.5em;
  font-weight: bold;
  padding: 15px 22px;
  margin: 10px;
  text-decoration: none;
}

.small-button {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: inline-block;
  font-size: 0.8em;
  font-weight: normal;
  margin: 10px;
  text-decoration: none;
}

.gray-button {
  @include buttonColor(#333, #eee);
}

div.welcome-image {
  align-items: center;
  width: 100%;

  img {
    margin: 0 auto;
    display: block;
  }

  .footer-img {
    max-height: 60px;
    width: auto;
  }
}

h1.landing-page-heading {
  padding: 60px 0 20px 0;
  font-size: 3em;
}

div.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}
