.legend {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 5px 5px 5px;
  flex-direction: row;
  width: auto;
  height: auto;
  font-weight: bold;
  align-items: baseline;

  .working-status {
    margin: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 4px 7px 4px 7px;
  }
}

.sched-readonly-schedule-flowsheet {
  .initial {
    border: 1px solid $black;
    padding: 4px;
    margin: 0;
  }
  .occ-header {
    font-size: 12px;
    font-weight: bold;
    border-bottom: 2px solid $black;
  }
  .signature {
    border: 1px solid $black;
    padding: 8px;
    height: 32px;
  }
}

#overlay {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4242;
}
#baseline-overlay {
  @extend #overlay;
  position: absolute;
}
#overlayContainer,
#baseline-overlayContainer {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
}

#calendar-scroll-select-header {
  background-color: lightgrey;
}

.resolution-status {
  font-weight: bold;
}

div.denied-reason-box {
  padding: 1em 0.5em 1em 0.5em;
  p {
    font-size: 1em;
  }
}

.status-GRANTED,
.status-Granted,
.status-Grant {
  color: #006f00;
}
.status-DENIED,
.status-Denied,
.status-Deny {
  color: #8b0000;
}

.status-WITHDRAWN,
.status-Withdrawn,
.status-withdrawn {
  color: #e67e00;
}

span.expand-shift-block {
  display: inline-block;
}

td.align_middle {
  vertical-align: middle;
}

#sched-prebook-group-review,
#sched-notify-review {
  #submittable-nav {
    padding: 0;
  }

  .header {
    border-top: 1px solid $nc-very-dark-grey;
    border-bottom: 1px solid $nc-very-dark-grey;
    padding: 8px;
    li {
      display: inline-block;
      padding-left: 8px;
      padding-right: 8px;
      *display: inline;
    }
    div.prev-link {
      float: left;
    }
    div.next-link {
      float: right;
    }
    div.central-header {
      text-align: center;
      font-weight: bold;
      margin-bottom: 2em;
    }
    div.central-subheader {
      text-align: center;
      font-size: 10px;
      li div.highlighted {
        float: right;
      }
    }
    div.central-status {
      text-align: center;
      font-size: 10px;
      color: $nc-very-dark-grey;
    }
  }
  .no-top-border {
    border-top: 0 solid black;
  }

  .no-bottom-border {
    border-bottom: 0 solid black;
  }
  table.review {
    th {
      margin-top: 2px;
    }
    td {
      border-bottom: none;
    }
    ul {
      margin-left: auto;
      margin-right: auto;
      li {
        display: inline-block;
        vertical-align: top;
        width: 299px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
  table.responses {
    th {
      margin-top: 2px;
      border-top: 1px solid black;
    }
  }
}

div.half-width {
  width: 50%;
  margin: 0 auto;
}

table {
  td.top-aligned {
    vertical-align: top;
  }
}

table#guidelines-table {
  box-sizing: border-box;

  th,
  td {
    text-align: center;
    vertical-align: middle;
  }

  .total {
    font-weight: bold;
    background-color: $grey;
  }
  .working-shift {
    background-color: $nc-working-day;
  }
  .premium-shift {
    background-color: $nc-premium-day;
  }
}

.left-justify-underline {
  text-align: left;
  text-decoration: underline;
}

table.submissions {
  thead > tr > th {
    vertical-align: middle;
    padding: 4px 10px 4px 5px;
  }
}

div.bottomRight {
  p {
    text-align: center;
  }
  margin-top: -20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 12px;
  float: right;
  font-weight: bold;
}

.multi-item-process-table {
  tr.view {
    td {
      padding-top: 16px;
    }
  }
  tr.edit {
    td {
      border-bottom: 1px solid $black;
      background-color: $nc-light-grey;
    }
  }
  div.header-row {
    border-bottom: 1px solid grey;
    background-color: $nc-light-grey;
    margin-top: 8px;
  }
  div.border-bottom {
    border-bottom: 1px solid $nc-light-grey;
  }
  div.table-rows {
    display: table;
    width: 100%;
    div.cell {
      padding: 8px;
      display: table-cell;
      vertical-align: middle;
    }
    div.cell-hidden {
      display: none;
    }
    div.cell-input {
      td {
        text-align: right;
      }
    }
    div.cell-state {
      width: 128px;
    }
    div.cell-save {
      position: relative;
      .saved-status {
        position: absolute;
        bottom: 16px;
        left: 0;
        display: none;
        text-align: center;
        color: darkgreen;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    table.inner {
      textarea {
        height: auto;
        width: 100%;
      }
      select {
        width: 100%;
      }
      margin-bottom: 0;
      tr {
        border: none;
      }
      td {
        padding: 2px;
        border: none;
      }
      td.input-cell {
        width: 300px;
      }
      td.input-cell-slim {
        width: 200px;
      }
    }
  }
  tr.error-row {
    display: none;
    div.error {
      margin-bottom: 0;
    }
    ul li {
      list-style-type: none;
    }
  }
}

.gray-background {
  background: #f4f4f6;
}

.submit-button {
  margin-top: 2px;
  margin-bottom: 2px;
}

.grouped.submit-button {
  margin-bottom: 20px;
}

.vacation-request-shift-summary-container .vacation-request-shift-summary {
  padding: 9px 4px;
}

.vacation-request-shift-summary-container .comments {
  padding: 4px;
}

.employee-to-manager-comment,
.manager-to-scheduling-comment {
  word-break: break-word;
  word-wrap: break-word;
}

.process-button {
  width: 5em;
}

td > .control-group > .control > trix-toolbar > .trix-button-row > .trix-button-group {
  margin-left: 0 !important;
}

.process-button-band {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  @include mobile {
    justify-content: space-between;
  }
  @include tablet {
    justify-content: flex-end;
  }
}

.processing-column-block {
  @include mobile {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.processing-navigation-header {
  position: relative;
  display: flex;
  flex-direction: column;
}

.processing-navigation-title {
  display: flex;
  align-self: center;
  @include mobile {
    align-self: auto;
    justify-content: space-between;
  }
}

.processing-navigation-buttons-container {
  @include tablet {
    position: absolute;
    left: 0;
    right: 0;
  }
}

.processing-navigation-buttons {
  display: flex;
  justify-content: space-between;
}
