// Shout-outs to my peeps at: http://www.bestcssbuttongenerator.com/

@mixin buttonColor($fontColor, $bgColor) {
  $shadowColor: darken($bgColor, 10%);
  -moz-box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  -webkit-box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  box-shadow: 1px 1px 0px 0px $nc-dark-grey, inset 0px 1px 0px 0px $shadowColor;
  background-color: darken($bgColor, 10%);
  border: 1px solid lighten($fontColor, 10%);
  color: $fontColor;
  //text-shadow:0px 1px 0px darken($fontColor, 10%);
  &:hover {
    background-color: darken($bgColor, 20%);
  }
  &:visited,
  &:link {
    color: $fontColor;
  }
  &:active {
    position: relative;
    top: 1px;
    left: 1px;
    -moz-box-shadow: inset 0px 1px 0px 0px $shadowColor;
    -webkit-box-shadow: inset 0px 1px 0px 0px $shadowColor;
    box-shadow: inset 0px 1px 0px 0px $shadowColor;
  }
  &:disabled {
    -moz-box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    -webkit-box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    box-shadow: 1px 1px 0px 0px $nc-dark-grey;
    border-color: desaturate($fontColor, 60%);
    background-color: desaturate($bgColor, 60%);
    color: desaturate($fontColor, 60%);
  }
}

.big-button {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 22px;
  margin: 5px;
  text-decoration: none;
}

.big-button-gray {
  @include buttonColor(#333, #eee);
}
.big-button-green {
  @include buttonColor(#168412, #dbffd3);
}
.big-button-blue {
  @include buttonColor(#2a4d84, #c1ddff);
}
.big-button-red {
  @include buttonColor(#99181c, #ffcbc5);
}

.medium-button {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 11px;
  margin: 5px;
  text-decoration: none;
}

.medium-button-with-icon {
  @extend .medium-button;
  padding: 0px;
}

.medium-button-gray {
  @include buttonColor(#333, #eee);
}
.medium-button-green {
  @include buttonColor(#168412, #dbffd3);
}
.medium-button-blue {
  @include buttonColor(#2a4d84, #c1ddff);
}
.medium-button-red {
  @include buttonColor(#99181c, #ffcbc5);
}
